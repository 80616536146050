import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class WarrantiesService {

    warranties = [];
    
    constructor(
        private apiService: ApiService
    ) { }

    public setWarranties(warranties) {
        this.warranties = warranties;
    }

    public async getWarranties() {
        if(!this.warranties.length) {
            return await this.getAll();
        } else {
            return this.warranties;
        }
    }

    public getAll() {
        return this.apiService.get('warranties');
    }

    public getWarrantyById(id) {
        return this.apiService.get('warranty/' + id);
    }

    public post(body) {
        return this.apiService.post('warranties', body);
    }

    public patch(id, body) {
        return this.apiService.patch('warranties/' + id, body);
    }

    public delete(id) {
        return this.apiService.delete('warranties/' + id);
    }
}
