import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ProductsService {

    products = [];
    
    constructor(
        private apiService: ApiService
    ) { }

    public async getAll(hard = false) {
        if(!this.products.length || hard) {
            let products: any = await this.apiService.get('products/cached?all=true');
            this.products = products;
            return products;
        } else {
            return this.products;
        }
    }

    public async getByText(text) {
        return await this.apiService.get(`products-by-text/${text}?all=true`);
    }

    public async updateProduct(product) {
        return await this.apiService.patch(`products/product/${product.get('_id')}`, product);
    }

    public async createProduct(product) {
        return await this.apiService.post('products', product);
    }

    public async deleteProduct(id, body?) {
        return await this.apiService.delete(`products/product/${id}`, body);
    }

    public async productByURL(url) {
        return await this.apiService.get('products/product-by-url?url=' + url);
    }

    public async reloadCache() {
        return await this.apiService.post('products/reset-cache', {});
    }
}
