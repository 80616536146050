import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class FiltersService {
    
    constructor(
        private apiService: ApiService
    ) { }

    public getAll() {
        return this.apiService.get('filters');
    }

    public getByCategory(category) {
        return this.apiService.get('filters/' + category);
    }

    public async update(filter) {
        return await this.apiService.patch(`filters/${filter._id}`, filter);
    }

    public async create(filter) {
        return await this.apiService.post('filters', filter);
    }

    public async delete(id, body?) {
        return await this.apiService.delete(`filters/${id}`, body);
    }
}
