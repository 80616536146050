import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PromosService {

    promos = [];
    
    constructor(
        private apiService: ApiService
    ) { }

    public async getAll(hard = false) {
        if(!this.promos.length || hard) {
            let promos: any = await this.apiService.get('promos');
            this.promos = promos;
            return promos;
        } else {
            return this.promos;
        }
    }

    public async update(promo) {
        return await this.apiService.patch(`promos/${promo._id}`, promo);
    }

    public async create(promo) {
        return await this.apiService.post('promos', promo);
    }

    public async delete(id, body?) {
        return await this.apiService.delete(`promos/${id}`, body);
    }
}
