import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inforce-file-upload',
  templateUrl: './inforce-file-upload.component.html',
  styleUrls: ['./inforce-file-upload.component.css']
})
export class InforceFileUploadComponent {
  @Input() onFileUpload = (el) => console.log('Open handler not implemented');

  onFileSelected(event) {
    this.onFileUpload(event);
  }
}