import { Component, Input, Output, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss']
})

export class AutocompleteComponent implements AfterViewInit {
	@ViewChild('AUTOCOMPLETE') autocomplete: ElementRef;
	@Input() set items(value: Array<string>) {
		this._items = value;
	}
	@Input() placeholder;
	
	@Output() itemChange = new EventEmitter();

	currentFocus = -1;
	_items = [];

	ngAfterViewInit() {
		var self = this;
		this.autocomplete.nativeElement.addEventListener("input", function(e) {
			var a, b, i, val = this.value;
			/*close any already open lists of autocompleted values*/
			self.closeAllLists(null);
			if (!val) { return false;}
			self.currentFocus = -1;
			/*create a DIV element that will contain the items (values):*/
			a = document.createElement("DIV");
			a.setAttribute("id", this.id + "autocomplete-list");
			a.setAttribute("class", "autocomplete-items");
			/*append the DIV element as a child of the autocomplete container:*/
			this.parentNode.appendChild(a);
			/*for each item in the array...*/
			for (i = 0; i < self._items.length; i++) {
				const product = self._items[i];
				const name = product.url;
				/*check if the item starts with the same letters as the text field value:*/
				if (name.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
				/*create a DIV element for each matching element:*/
				b = document.createElement("DIV");
				/*make the matching letters bold:*/
				b.innerHTML = "<strong>" + name.substr(0, val.length) + "</strong>";
				b.innerHTML += name.substr(val.length);
				/*insert a input field that will hold the current array item's value:*/
				b.innerHTML += "<input type='hidden' value='" + name + "'>";
				/*execute a function when someone clicks on the item value (DIV element):*/
					b.addEventListener("click", function(e) {
					/*insert the value for the autocomplete text field:*/
						self.autocomplete.nativeElement.value = this.getElementsByTagName("input")[0].value;
						self.autocomplete.nativeElement.dataset.id = product._id;
						/*close the list of autocompleted values,
						(or any other open lists of autocompleted values:*/
						self.closeAllLists(null);
						self.itemChange.emit(product);
					});
				a.appendChild(b);
				}
			}
		});
		/*execute a function presses a key on the keyboard:*/
		this.autocomplete.nativeElement.addEventListener("keydown", function(e) {
			var x = document.getElementById(this.id + "autocomplete-list");
			if (x) x = x.getElementsByTagName("div") as any;
			if (e.keyCode == 40) {
				/*If the arrow DOWN key is pressed,
				increase the currentFocus variable:*/
				self.currentFocus++;
				/*and and make the current item more visible:*/
				self.addActive(x, self.currentFocus);
			} else if (e.keyCode == 38) { //up
				/*If the arrow UP key is pressed,
				decrease the currentFocus variable:*/
				self.currentFocus--;
				/*and and make the current item more visible:*/
				self.addActive(x, self.currentFocus);
			} else if (e.keyCode == 13) {
				/*If the ENTER key is pressed, prevent the form from being submitted,*/
				e.preventDefault();
				if (self.currentFocus > -1) {
				/*and simulate a click on the "active" item:*/
				if (x) x[self.currentFocus].click();
				}
			}
		});
		document.addEventListener("click", (e) => {
			self.closeAllLists(e.target);
		});
	};

	closeAllLists(elmnt) {
		/*close all autocomplete lists in the document,
		except the one passed as an argument:*/
		var x = document.getElementsByClassName("autocomplete-items");
		for (var i = 0; i < x.length; i++) {
			if (elmnt != x[i] && elmnt != this.autocomplete.nativeElement) {
				x[i].parentNode.removeChild(x[i]);
			}
		}
	}
	
	removeActive(x) {
		/*a function to remove the "active" class from all autocomplete items:*/
		for (var i = 0; i < x.length; i++) {
		x[i].classList.remove("autocomplete-active");
		}
	}

	addActive(x, currentFocus) {
		/*a function to classify an item as "active":*/
		if (!x) return false;
		/*start by removing the "active" class on all items:*/
		this.removeActive(x);
		if (currentFocus >= x.length) currentFocus = 0;
		if (currentFocus < 0) currentFocus = (x.length - 1);
		/*add class "autocomplete-active":*/
		x[currentFocus].classList.add("autocomplete-active");
		// addressChange
	}
}